<template>
  <v-card class="mx-auto">
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title>{{ $t("__order") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn outlined @click="reloadOrders">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-btn class="ml-2" outlined @click="printOrders">
        <v-icon>mdi-printer</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-title>
      <v-row align="center" justify="center">
        <v-col :cols="isMobile ? 12 : 4">
          <v-combobox
            v-model="storeSelect"
            :items="storeItems"
            item-text="name"
            item-value="id"
            :label="$t('__order_store')"
            hide-details
            outlined
            dense
            @change="orderTimeSelect = 'month'"
          >
          </v-combobox>
        </v-col>
        <v-col :cols="isMobile ? 12 : 8">
          <v-row align="center">
            <v-col cols="4">
              <v-select
                v-model="orderTimeSelect"
                :items="orderTimeItems"
                item-text="ch"
                item-value="eg"
                :label="$t('__orderlist_time')"
                outlined
                dense
                hide-details
                @change="initData"
              ></v-select>
            </v-col>
            <template v-if="orderTimeSelect === 'customDate'">
              <v-col cols="4">
                <date-picker
                  :outlined="true"
                  :dense="true"
                  :date.sync="startDate"
                  :date-label="$t('__orderlist_start_date')"
                  :locale="$i18n.locale"
                />
              </v-col>
              <v-col cols="4">
                <date-picker
                  :outlined="true"
                  :dense="true"
                  :date.sync="endDate"
                  :date-label="$t('__orderlist_end_date')"
                  :locale="$i18n.locale"
                  :min-date="startDate"
                />
              </v-col>
            </template>
            <template v-else-if="orderTimeSelect === 'customMonth'">
              <v-col cols="4">
                <month-picker
                  :outlined="true"
                  :dense="true"
                  :month.sync="monthSelect"
                  :month-label="$t('__orderlist_month')"
                  :locale="$i18n.locale"
                />
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-app id="printTable">
        <v-alert
          class="mb-0"
          border="left"
          colored-border
          type="info"
          elevation="0"
        >
          <v-progress-circular
            v-if="countLoading"
            indeterminate
            color="primary"
          />
          <template v-else>
            <p class="mb-0">
              {{ $t("__orderlist_total_sales") + ": "
              }}{{ totalSales | currency }}
            </p>
            <p class="mb-0">
              {{ $t("__orderlist_accounts_receivable") + ": "
              }}{{ accountsReceivable | currency }}
            </p>
            <p class="mb-0">
              {{ $t("__orderlist_accounts_received") + ": "
              }}{{ accountsReceived | currency }}
            </p>
          </template>
        </v-alert>
        <v-data-table
          :headers="headers"
          :items="orders"
          :loading="loading"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template v-slot:[`item.id`]="{ item }">
            <v-btn x-small text color="primary" @click="toOrder(item)">{{
              item.id
            }}</v-btn>
          </template>
          <template v-slot:[`item.data.price`]="{ item }"
            >${{ item.data.price }}</template
          >
          <template v-slot:[`item.orderStatus`]="{ item }">
            <v-chip :color="item.orderStatusColor" class="white--text">
              {{ $t(`__order_status_${item.orderStatus}`) }}
            </v-chip>
          </template>
          <template v-slot:[`item.logisticsNumber`]="{ item }">
            <div v-html="item.logisticsNumber"></div>
          </template>
          <template v-slot:[`item.paymentStatus`]="{ item }">
            <v-chip :color="item.paymentStatusColor"
              >{{ $t(`__payment_status_${item.paymentStatus}`) }}
            </v-chip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <template
              v-if="
                item.orderStatus != 'completed' &&
                  item.orderStatus != 'cancelled'
              "
            >
              <template v-if="item.isRequestCancellation">
                <v-btn
                  small
                  color="primary"
                  class="ma-1"
                  @click="replyOrderCancellation(item)"
                >
                  <v-icon left>mdi-alert</v-icon>
                  {{ $t("__order_action_reply_cancellation") }}
                </v-btn>
              </template>
              <template v-else>
                <template v-if="item.orderStatus == 'unaccepted'">
                  <!-- unaccepted -->
                  <v-btn
                    small
                    color="green white--text"
                    class="ma-1"
                    @click="acceptOrder(item)"
                  >
                    <v-icon left>mdi-check</v-icon>
                    {{ $t("__accept") }}
                  </v-btn>
                  <v-btn
                    small
                    color="red white--text"
                    class="ma-1"
                    @click="cancelOrder(item)"
                  >
                    <v-icon left>mdi-close</v-icon>
                    {{ $t("__reject") }}
                  </v-btn>
                </template>
                <template v-if="item.orderStatus == 'accepted'">
                  <!-- accepted -->
                  <template
                    v-if="
                      item.paymentStatus === 'unpaid' &&
                        item.data['payment_method'] !== 'cash-on-delivery'
                    "
                  >
                    <!-- unpaid -->
                    <v-btn
                      small
                      color="yellow"
                      class="ma-1"
                      @click="addPayment(item)"
                    >
                      <v-icon left>mdi-currency-usd</v-icon>
                      {{ $t("__order_action_pay") }}
                    </v-btn>
                    <template v-if="item.unconfirmedTransferInfoIndex >= 0">
                      <v-btn
                        small
                        color="primary"
                        class="ma-1"
                        @click="replyTransferInfo(item)"
                      >
                        <v-icon left>mdi-alert</v-icon>
                        {{ $t("__order_action_reply_transferinfo") }}
                      </v-btn>
                    </template>
                  </template>
                  <template v-else>
                    <!-- paid -->
                    <v-btn
                      small
                      color="teal white--text"
                      class="ma-1"
                      @click="ship(item)"
                    >
                      <v-icon left>mdi-truck</v-icon>
                      {{ $t("__ship") }}
                    </v-btn>
                  </template>
                </template>
                <template
                  v-if="
                    item.orderStatus === 'shipped' ||
                      item.orderStatus === 'received'
                  "
                >
                  <!-- shipped or received -->
                  <v-btn
                    small
                    color="green white--text"
                    class="ma-1"
                    @click="completeOrder(item)"
                  >
                    <v-icon left>mdi-check</v-icon>
                    {{ $t("__complete") }}
                  </v-btn>
                </template>
              </template>
            </template>
          </template>
        </v-data-table>
      </v-app>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import {
  format,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  startOfDay,
  endOfDay,
  formatISO
} from "date-fns";

export default {
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      isMobile: "isMobile",
      userOrders: "orders/list",
      userPaidAmounts: "orders/paidAmountDatas",
      userTransferInfos: "orders/transferInfoLists",
      stores: "stores/list",
      storeIDs: "stores/ids",
      orderMessages: "messages/list"
    }),
    storeItems() {
      let stores = [];
      for (const store of this.stores) {
        let storeName = store.data.name;
        let storeID = store.id;
        stores.push({
          name: storeName,
          id: storeID
        });
      }
      return stores;
    },
    totalSales() {
      let totalSales = 0;
      this.orders.forEach(item => {
        totalSales += item.data.price;
      });
      return totalSales;
    },
    accountsReceivable() {
      let receivable = 0;
      this.orders.forEach(item => {
        if (item.paymentStatus === "unpaid") {
          receivable += item.data.price;
        }
      });
      return receivable;
    },
    accountsReceived() {
      let received = 0;
      this.orders.forEach(item => {
        if (item.paymentStatus === "paid") {
          received += item.data.price;
        }
      });
      return received;
    }
  },
  data() {
    return {
      orders: [],
      headers: [
        {
          text: `${this.$t("__orderlist_id")}`,
          value: "id",
          align: "start"
        },
        {
          text: `${this.$t("__orderlist_time")}`,
          value: "timeFormatted",
          align: "start"
        },
        {
          text: `${this.$t("__orderlist_price")}`,
          value: "data.price",
          align: "start"
        },
        {
          text: `${this.$t("__orderlist_orderstatus")}`,
          value: "orderStatus",
          align: "start"
        },
        {
          text: `${this.$t("__orderlist_logistics_number")}`,
          value: "logisticsNumber",
          align: "center"
        },
        {
          text: `${this.$t("__orderlist_paymentstatus")}`,
          value: "paymentStatus",
          align: "start"
        },
        {
          text: `${this.$t("__orderlist_actions")}`,
          value: "actions",
          width: "5",
          align: "end",
          sortable: false
        }
      ],
      storeSelect: "",
      monthSelect: format(new Date(), "yyyy-MM"),
      sortBy: "timeFormatted",
      sortDesc: true,
      startDate: format(new Date(), "yyyy-MM-dd"),
      endDate: format(new Date(), "yyyy-MM-dd"),
      orderTimeItems: [
        {
          ch: this.$t("__orderlist_today"),
          eg: "today"
        },
        {
          ch: this.$t("__orderlist_this_week"),
          eg: "week"
        },
        {
          ch: this.$t("__orderlist_this_month"),
          eg: "month"
        },
        {
          ch: this.$t("__orderlist_custom_date"),
          eg: "customDate"
        },
        {
          ch: this.$t("__orderlist_custom_month"),
          eg: "customMonth"
        }
      ],
      orderTimeSelect: "month",
      countLoading: false
    };
  },
  methods: {
    update() {
      this.orders = [];
      this.countLoading = true;
      for (const userOrder of this.userOrders) {
        const order = {
          id: userOrder.id,
          data: userOrder.data,
          logisticsNumber: userOrder.data.logistics_number
            ? `${userOrder.data.shipping_name}<br/>${userOrder.data.logistics_number}`
            : "-",
          timeFormatted: format(
            new Date(userOrder.data.create_time),
            "yyyy/MM/dd HH:mm:ss"
          ),
          contactName:
            userOrder.data.consumer.last_name +
            " " +
            userOrder.data.consumer.first_name,
          contactPhoneNumber: userOrder.data.consumer.phone_number,
          orderStatus: userOrder.data.status,
          orderStatusColor: this.orderColor(userOrder.data.status),
          paidAmount: 0,
          paymentStatus: "unpaid",
          paymentStatusColor: "error",
          isRequestCancellation: userOrder.data.request_cancellation_time
            ? !(
                userOrder.data.request_cancellation_time ==
                "0001-01-01T00:00:00Z"
              )
            : false,
          unconfirmedTransferInfoIndex: -1,
          isLogistics:
            userOrder.data["shipping_channel"] === "logistics" ? true : false
        };

        // payment status
        if (this.userPaidAmounts[order.id]) {
          order.paidAmount = this.userPaidAmounts[order.id];
          if (order.paidAmount.amount >= order.data.price) {
            order.paymentStatus = "paid";
            order.paymentStatusColor = "success";
          }
        } else {
          return;
        }

        // receivce unconfirmed transfer info
        const orderTransferInfos = this.userTransferInfos[order.id];
        if (orderTransferInfos) {
          for (const i in orderTransferInfos) {
            if (orderTransferInfos[i].data.status === "unconfirmed") {
              order.unconfirmedTransferInfoIndex = i;
              break;
            }
          }
        }

        this.orders.push(order);
      }
      this.countLoading = false;
    },
    loadStores() {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("stores/getUserStores")
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          console.log(err);
          this.$store.dispatch("setIsLoading", false);
        });
    },
    loadOrders(
      storeID = this.storeIDs[0],
      startTime = this.getStartMonth(),
      endTime = this.getEndMonth()
    ) {
      this.$store.dispatch("setIsLoading", true);
      let userID = "";
      let consumerID = "";
      let status = "all";
      return this.$store
        .dispatch("orders/getOrders", {
          userID,
          storeID,
          consumerID,
          status,
          startTime,
          endTime
        })
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          console.log(err);
          this.$store.dispatch("setIsLoading", false);
        });
    },
    reloadOrders() {
      this.loadOrders();
    },
    printOrders() {
      const option = {
        name: "_blank",
        specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
        styles: [
          "https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900",
          "https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css",
          "https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css"
        ],
        windowTitle: this.$t("__orderlist")
      };
      this.$htmlToPaper("printTable", option);
    },
    toOrder(order) {
      this.$emit("toOrder", order);
    },
    acceptOrder(order) {
      this.$emit("acceptOrder", order);
    },
    cancelOrder(order) {
      this.$emit("cancelOrder", order);
    },
    ship(order) {
      this.$emit("ship", order);
    },
    completeOrder(order) {
      this.$emit("completeOrder", order);
    },
    addPayment(order) {
      this.$emit("addPayment", order);
    },
    replyOrderCancellation(order) {
      this.$emit("replyOrderCancellation", order);
    },
    replyTransferInfo(order) {
      this.$emit("replyTransferInfo", {
        order,
        transferInfo: this.userTransferInfos[order.id][
          order.unconfirmedTransferInfoIndex
        ]
      });
    },
    orderColor(status) {
      let color;
      switch (status) {
        case "unaccepted":
          color = "error";
          break;
        case "accepted":
          color = "primary lighten-2";
          break;
        case "shipped":
          color = "cyan lighten-2";
          break;
        case "received":
          color = "lime";
          break;
        case "completed":
          color = "success";
          break;
        case "cancelled":
          color = "grey";
          break;
        default:
          break;
      }
      return color;
    },
    getStartMonth() {
      const startMonth = startOfMonth(new Date());
      const formatted = formatISO(startMonth);
      return formatted;
    },
    getEndMonth(month = new Date()) {
      const endMonth = endOfMonth(month);
      const formatted = formatISO(endMonth);
      return formatted;
    },
    getStartWeek() {
      const startWeek = startOfWeek(new Date(), { weekStartsOn: 1 });
      const formatted = formatISO(startWeek);
      return formatted;
    },
    getEndWeek() {
      const endWeek = endOfWeek(new Date(), { weekStartsOn: 1 });
      const formatted = formatISO(endWeek);
      return formatted;
    },
    getStartDay(day) {
      const startDay = startOfDay(day);
      const formatted = formatISO(startDay);
      return formatted;
    },
    getEndDay(day) {
      const endDay = endOfDay(day);
      const formatted = formatISO(endDay);
      return formatted;
    },
    initData() {
      this.monthSelect = format(new Date(), "yyyy-MM");
      this.startDate = format(new Date(), "yyyy-MM-dd");
      this.endDate = format(new Date(), "yyyy-MM-dd");
    }
  },
  created() {
    this.loadStores();
  },
  watch: {
    userOrders: {
      deep: true,
      handler() {
        this.update();
      }
    },
    userPaidAmounts: {
      deep: true,
      handler() {
        this.update();
      }
    },
    userTransferInfos: {
      deep: true,
      handler() {
        this.update();
      }
    },
    storeItems: {
      immediate: true,
      handler(val) {
        if (val) this.storeSelect = val[0];
      }
    },
    storeSelect: {
      deep: true,
      handler(val) {
        if (val) this.loadOrders(val.id);
      }
    },
    orderTimeSelect: {
      handler(val) {
        let startTime = formatISO(new Date(this.startDate));
        let endTime = formatISO(new Date(this.endDate));
        switch (val) {
          case "today":
            startTime = this.getStartDay(new Date());
            endTime = this.getEndDay(new Date());
            break;
          case "week":
            startTime = this.getStartWeek();
            endTime = this.getEndWeek();
            break;
          case "month":
            startTime = this.getStartMonth();
            endTime = this.getEndMonth();
            break;
          case "customMonth":
            startTime = this.getStartMonth();
            endTime = this.getEndMonth();
            break;
          case "customDate":
            startTime = this.getStartDay(new Date());
            endTime = this.getEndDay(new Date());
            break;
          default:
            break;
        }
        this.loadOrders(this.storeSelect.id, startTime, endTime);
      }
    },
    monthSelect: {
      handler(val) {
        const startDateParam = this.getStartDay(new Date(val));
        const endDateParam = this.getEndMonth(new Date(val));
        this.loadOrders(this.storeSelect.id, startDateParam, endDateParam);
      }
    },
    startDate: {
      handler(val) {
        const startDateParam = this.getStartDay(new Date(val));
        const endDateParam = this.getEndDay(new Date(this.endDate));
        this.loadOrders(this.storeSelect.id, startDateParam, endDateParam);
      }
    },
    endDate: {
      handler(val) {
        const startDateParam = this.getStartDay(new Date(this.startDate));
        const endDateParam = this.getEndDay(new Date(val));
        this.loadOrders(this.storeSelect.id, startDateParam, endDateParam);
      }
    }
  }
};
</script>
