<template>
  <div class="order-list">
    <v-container fluid>
      <v-row align="center" justify="center" dense>
        <v-col cols="12">
          <OrderList
            ref="OrderList"
            :loading.sync="loading"
            @toOrder="toOrder"
            @acceptOrder="toAcceptOrder"
            @cancelOrder="toCancelOrder"
            @ship="toShip"
            @completeOrder="toCompleteOrder"
            @addPayment="toAddPayment"
            @replyOrderCancellation="toReplyOrderCancellation"
            @replyTransferInfo="toReplyTransferInfo"
          />
          <AlertDialog
            :show="showAlertDialog"
            :title="alertDialogTitle"
            :htmltext="alertDialogHTMLText"
            :oktext="$t('__confirm')"
            :canceltext="$t('__cancel')"
            @ok="alertDialogOk"
            @cancel="alertDialogCancel"
          />
          <v-dialog v-model="showAddPaymentForm" persistent max-width="600px">
            <AddPaymentForm
              :paymentMethod="addPaymentFormMethod"
              :amount="addPaymentFormAmount"
              @ok="toAddPaymentConfirm"
              @cancel="addPaymentFormCancel"
            />
          </v-dialog>
          <v-dialog
            v-model="showReplyOrderCancellationForm"
            persistent
            max-width="600px"
          >
            <ReplyOrderCancellationForm
              @ok="toReplyOrderCancellationConfirm"
              @cancel="replyOrderCancellationFormCancel"
            />
          </v-dialog>
          <v-dialog
            v-model="showReplyTransferInfoForm"
            persistent
            max-width="600px"
          >
            <ReplyTransferInfoForm
              :transferInfo="replyTransferInfoFormTransferInfo"
              @ok="toReplyTransferInfoConfirm"
              @cancel="replyTransferInfoFormCancel"
            />
          </v-dialog>

          <v-dialog v-model="showShipForm" persistent max-width="600px">
            <ShipForm
              ref="ShipForm"
              :storeID="shipFormStoreID"
              :isLogistics="shipFormIsLogistics"
              @ok="toShipConfirm"
              @cancel="shipFormCancel"
            />
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { format } from "date-fns";
import OrderList from "@/components/Orders/OrderList";
import AlertDialog from "@/components/AlertDialog";
import AddPaymentForm from "@/components/Order/AddPaymentForm";
import ReplyOrderCancellationForm from "@/components/Order/ReplyOrderCancellationForm";
import ReplyTransferInfoForm from "@/components/Order/ReplyTransferInfoForm";
import ShipForm from "@/components/Order/ShipForm";

export default {
  name: "Order",
  components: {
    OrderList,
    AlertDialog,
    AddPaymentForm,
    ReplyOrderCancellationForm,
    ReplyTransferInfoForm,
    ShipForm
  },
  computed: {
    ...mapGetters({
      userPayments: "orders/paymentLists"
    })
  },
  data() {
    return {
      loading: false,
      showDialog: false,
      dialogAction: "create",
      showAlertDialog: false,
      alertDialogTitle: "",
      alertDialogHTMLText: "",
      alertDialogAction: null,
      alertDialogData: null,
      showAddPaymentForm: false,
      addPaymentFormOrder: null,
      addPaymentFormAmount: 0,
      addPaymentFormMethod: "",
      showReplyOrderCancellationForm: false,
      replyOrderCancellationFormOrder: null,
      showReplyTransferInfoForm: false,
      replyTransferInfoFormOrder: null,
      replyTransferInfoFormTransferInfo: null,
      showShipForm: false,
      shipFormOrder: null,
      shipFormStoreID: null,
      shipFormIsLogistics: false
    };
  },
  methods: {
    toOrder(order) {
      this.$router.push({ name: "Order", params: { id: order.id } });
    },
    toAcceptOrder(order) {
      this.alertDialogTitle = this.$t("__order_accept_title");
      this.alertDialogHTMLText = this.$t("__order_accept_text");
      this.alertDialogAction = this.acceptOrder;
      this.alertDialogData = order;
      this.showAlertDialog = true;
    },
    toCancelOrder(order) {
      this.alertDialogTitle = this.$t("__order_cancel_title");
      this.alertDialogHTMLText = this.$t("__order_cancel_text");
      this.alertDialogAction = this.cancelOrder;
      this.alertDialogData = order;
      this.showAlertDialog = true;
    },
    toShip(order) {
      this.shipFormOrder = order;
      if (this.$refs.ShipForm) this.$refs.ShipForm.clear();
      this.showShipForm = true;
      if (order.isLogistics) {
        this.shipFormStoreID = order.data["store_id"];
      } else {
        this.shipFormStoreID = "";
      }
      this.shipFormIsLogistics = order.isLogistics;
    },
    toShipConfirm({
      logisticsNumber,
      logisticsCompanyName,
      logisticsTrackingURL
    }) {
      const order = this.shipFormOrder;
      this.showShipForm = false;
      this.alertDialogTitle = this.$t("__order_ship_title");
      this.alertDialogHTMLText = this.$t("__order_ship_text").replace(
        "%1",
        order.id
      );
      if (order.isLogistics) {
        this.alertDialogHTMLText +=
          `<table>` +
          `<tr><th style='text-align:left;vertical-align:top;padding-right:0.5em;'>${this.$t(
            "__order_shipping_logistics_number"
          )}</th><td>${logisticsNumber}</td></tr>` +
          `<tr><th style='text-align:left;vertical-align:top;padding-right:0.5em;'>${this.$t(
            "__order_shipping_logistics_company_name"
          )}</th><td>${logisticsCompanyName}</td></tr>` +
          `<tr><th style='text-align:left;vertical-align:top;padding-right:0.5em;'>${this.$t(
            "__order_shipping_logistics_tracking_url"
          )}</th><td>${logisticsTrackingURL}</td></tr>` +
          `</table>`;
      } else {
        this.alertDialogHTMLText +=
          `<table>` +
          `<tr><th style='text-align:left;vertical-align:top;padding-right:0.5em;'>${this.$t(
            "__order_shipping_pickup_number"
          )}</th><td>${logisticsNumber}</td></tr>` +
          `</table>`;
      }
      this.alertDialogAction = this.ship;
      this.alertDialogData = {
        order,
        logisticsNumber,
        logisticsCompanyName,
        logisticsTrackingURL
      };
      this.showAlertDialog = true;
    },
    toCompleteOrder(order) {
      const orderStatus = order.data.status;
      if (orderStatus == "shipped" || orderStatus == "accepted") {
        const shippedDate = format(
          new Date(order.data.statuses[order.data.statuses.length - 1].time),
          "T"
        );
        const nowDate = Date.now();
        const shippedDay = Math.round(
          (nowDate - shippedDate) / 1000 / 60 / 60 / 24
        );
        const waitDay = 7 - shippedDay;
        if (waitDay > 0) {
          alert(
            this.$t("__order_unreceived_message").replaceAll(
              "%1",
              waitDay.toString()
            )
          );
          return;
        }
      }
      this.alertDialogTitle = this.$t("__order_complete_title");
      this.alertDialogHTMLText = this.$t("__order_complete_text");
      this.alertDialogAction = this.completeOrder;
      this.alertDialogData = { order };
      this.showAlertDialog = true;
    },
    toAddPayment(order) {
      this.addPaymentFormOrder = order;
      this.addPaymentFormMethod = order.data.payment_method;
      let orderPaidAmount = 0.0;
      const orderPayments = this.userPayments[order.id];
      if (orderPayments) {
        for (const payment of orderPayments) {
          if (payment.data.status === "paid") {
            orderPaidAmount += payment.data.amount;
          }
        }
      }
      this.addPaymentFormAmount = order.data.price - orderPaidAmount;
      this.showAddPaymentForm = true;
    },
    toAddPaymentConfirm({
      method,
      amount,
      bankCode,
      account,
      accountLast5Digits
    }) {
      const order = this.addPaymentFormOrder;
      this.showAddPaymentForm = false;
      this.alertDialogTitle = this.$t(`__order_pay_in_${method}_title`);
      this.alertDialogHTMLText = this.$t(`__order_pay_in_${method}_text`)
        .replace("%1", order.id)
        .replace("%2", amount.toString());
      this.alertDialogAction = this.addPayment;
      this.alertDialogData = {
        order,
        method,
        status: "paid",
        amount: parseFloat(amount),
        bankCode,
        account,
        accountLast5Digits
      };
      this.showAlertDialog = true;
    },
    toReplyOrderCancellation(order) {
      this.replyOrderCancellationFormOrder = order;
      this.showReplyOrderCancellationForm = true;
    },
    toReplyOrderCancellationConfirm({ accept, description }) {
      const order = this.replyOrderCancellationFormOrder;
      this.showReplyOrderCancellationForm = false;
      this.alertDialogTitle = accept
        ? this.$t("__order_reply_accept_cancellation_title")
        : this.$t("__order_reply_reject_cancellation_title");
      this.alertDialogHTMLText = accept
        ? this.$t("__order_reply_accept_cancellation_text")
        : this.$t("__order_reply_reject_cancellation_text");
      this.alertDialogHTMLText = this.alertDialogHTMLText.replace(
        "%1",
        order.id
      );
      this.alertDialogHTMLText += `<table><tr><th style='text-align:left;vertical-align:top;padding-right:0.5em;'>${this.$t(
        "__order_reply_cancellation_text_description"
      )}</th><td>${description.replaceAll("\n", "<br/>")}</td></tr></table>`;
      this.alertDialogAction = this.replyOrderCancellation;
      this.alertDialogData = { order, accept, description };
      this.showAlertDialog = true;
    },
    toReplyTransferInfo({ order, transferInfo }) {
      this.replyTransferInfoFormOrder = order;
      this.replyTransferInfoFormTransferInfo = transferInfo;
      this.showReplyTransferInfoForm = true;
    },
    toReplyTransferInfoConfirm({ confirm, description }) {
      const order = this.replyTransferInfoFormOrder;
      const transferInfo = this.replyTransferInfoFormTransferInfo;
      this.showReplyTransferInfoForm = false;
      this.alertDialogTitle = confirm
        ? this.$t("__order_reply_confirm_transferinfo_title")
        : this.$t("__order_reply_reject_transferinfo_title");
      this.alertDialogHTMLText = confirm
        ? this.$t("__order_reply_confirm_transferinfo_text")
        : this.$t("__order_reply_reject_transferinfo_text");
      this.alertDialogHTMLText = this.alertDialogHTMLText.replace(
        "%1",
        order.id
      );
      this.alertDialogHTMLText +=
        `<table>` +
        `<tr><th style='text-align:left;vertical-align:top;padding-right:0.5em;'>${this.$t(
          "__order_reply_transferinfo_text_accountlast5digits"
        )}</th><td>${transferInfo.data.account_last_5_digits}</td></tr>` +
        `<tr><th style='text-align:left;vertical-align:top;padding-right:0.5em;'>${this.$t(
          "__order_reply_transferinfo_text_amount"
        )}</th><td>$${transferInfo.data.amount}</td></tr>` +
        `<tr><th style='text-align:left;vertical-align:top;padding-right:0.5em;'>${this.$t(
          "__order_reply_transferinfo_text_description"
        )}</th><td>${description.replaceAll("\n", "<br/>")}</td></tr>` +
        `</table>`;
      this.alertDialogAction = this.replyTransferInfo;
      this.alertDialogData = { order, transferInfo, confirm, description };
      this.showAlertDialog = true;
    },
    acceptOrder(order) {
      // console.log('acceptOrder: ', order)
      this.loading = true;
      return this.$store
        .dispatch("orders/acceptOrder", { id: order.id })
        .then(() => {
          this.$refs.OrderList.loadOrders();
          this.loading = false;
        })
        .catch(err => {
          console.error(err);
          this.loading = false;
        });
    },
    cancelOrder(order) {
      // console.log('cancelOrder: ', order)
      this.loading = true;
      return this.$store
        .dispatch("orders/cancelOrder", { id: order.id })
        .then(() => {
          this.$refs.OrderList.loadOrders();
          this.loading = false;
        })
        .catch(err => {
          console.error(err);
          this.loading = false;
        });
    },
    ship({
      order,
      logisticsNumber,
      logisticsCompanyName,
      logisticsTrackingURL
    }) {
      this.loading = true;
      return this.$store
        .dispatch("orders/setOrderShipped", {
          orderID: order.id,
          logisticsNumber,
          logisticsCompanyName,
          logisticsTrackingURL
        })
        .then(() => {
          this.$refs.OrderList.loadOrders();
          this.loading = false;
        })
        .catch(err => {
          console.error(err);
          this.loading = false;
        });
    },
    completeOrder({ order }) {
      this.loading = true;
      if (order.paidAmount.amount >= order.data["price"]) {
        return this.$store
          .dispatch("orders/completeOrder", { orderID: order.id })
          .then(() => {
            this.$refs.OrderList.loadOrders();
            this.loading = false;
          })
          .catch(err => {
            console.error(err);
            this.loading = false;
          });
      } else {
        if (order.data["payment_method"] === "cash-on-delivery") {
          const amount = order.data["price"] - order.paidAmount.amount;
          this.alertDialogTitle = this.$t(
            "__order_complete_payondelivery_title"
          );
          this.alertDialogHTMLText = this.$t(
            "__order_complete_payondelivery_text"
          )
            .replace("%1", order.id)
            .replace("%2", amount.toString());
          this.alertDialogAction = this.addCashOnDeliveryPaymentAndCompleteOrder;
          this.alertDialogData = { order, amount };
          this.showAlertDialog = true;
        } else {
          alert(this.$t("__order_complete_unpaid_text"));
        }
      }
    },
    addPayment({
      order,
      method,
      status,
      amount,
      bankCode,
      account,
      accountLast5Digits
    }) {
      this.loading = true;
      return this.$store
        .dispatch("orders/addPayment", {
          orderID: order.id,
          method,
          status,
          amount,
          bankCode,
          account,
          accountLast5Digits
        })
        .then(() => {
          this.$refs.OrderList.loadOrders();
          this.loading = false;
        })
        .catch(err => {
          console.error(err);
          this.loading = false;
        });
    },
    addCashOnDeliveryPaymentAndCompleteOrder({ order, amount }) {
      this.loading = true;
      return this.$store
        .dispatch("orders/addPayment", {
          orderID: order.id,
          method: "cash-on-delivery",
          status: "paid",
          amount
        })
        .then(() => {
          return this.$store.dispatch("orders/completeOrder", {
            orderID: order.id
          });
        })
        .then(() => {
          this.$refs.OrderList.loadOrders();
          this.loading = false;
        })
        .catch(err => {
          console.error(err);
          this.loading = false;
        });
    },
    replyOrderCancellation({ order, accept, description }) {
      // console.log('replyOrderCancellation: ', order, accept, description)
      this.loading = true;
      return this.$store
        .dispatch("orders/replyOrderCancellation", {
          id: order.id,
          accept,
          description
        })
        .then(() => {
          this.$refs.OrderList.loadOrders();
          this.loading = false;
        })
        .catch(err => {
          console.error(err);
          this.loading = false;
        });
    },
    replyTransferInfo({ order, transferInfo, confirm, description }) {
      // console.log('replyTransferInfo: ', { order, transferInfo, confirm, description })
      this.loading = true;
      return this.$store
        .dispatch("orders/replyTransferInfo", {
          orderID: order.id,
          transferInfoID: transferInfo.id,
          confirm,
          description
        })
        .then(() => {
          this.$refs.OrderList.loadOrders();
          this.loading = false;
        })
        .catch(err => {
          console.error(err);
          this.loading = false;
        });
    },
    alertDialogOk() {
      this.showAlertDialog = false;
      this.alertDialogAction(this.alertDialogData);
    },
    alertDialogCancel() {
      this.showAlertDialog = false;
    },
    addPaymentFormCancel() {
      this.showAddPaymentForm = false;
    },
    replyOrderCancellationFormCancel() {
      this.showReplyOrderCancellationForm = false;
    },
    replyTransferInfoFormCancel() {
      this.showReplyTransferInfoForm = false;
    },
    shipFormCancel() {
      this.showShipForm = false;
    }
  }
};
</script>
